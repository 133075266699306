/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

function Privacy() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="10">
            <Card>
              
              <CardBody>
                <div className="typography-line">
              

    <h1>How to Calculate the 75th Percentile Speed in Biking</h1>
    <p>The 75th percentile (or third quartile, Q3) in biking speed is a statistical measure that indicates the speed above which the fastest 25% of your ride occurred. Here's how it's calculated:</p>

    <ol>
        <li>
            <strong>Collect the Data:</strong> Gather all the speed data points from your ride. Depending on how the data is recorded, you could have speed data at every second, minute, or specific intervals.
        </li>

        <li>
            <strong>Sort the Speeds:</strong> Arrange all the speed data points in ascending order, from the slowest to the fastest speed during the ride.
        </li>

        <li>
            <strong>Locate the 75th Percentile Position:</strong> The position of the 75th percentile is calculated as follows:
            <pre>P = (n + 1) × 0.75</pre>
            <p>Where <em>P</em> is the position of the 75th percentile in the sorted list, and <em>n</em> is the total number of speed data points.</p>

            <p>For example, if you have 100 speed data points, the position of the 75th percentile would be:</p>
            <pre>P = (100 + 1) × 0.75 = 75.75</pre>
        </li>

        <li>
            <strong>Interpolate if Needed:</strong> If <em>P</em> is a whole number, the speed at that position is the 75th percentile speed. If <em>P</em> is not a whole number (like 75.75), you interpolate between the speed values at the 75th and 76th positions to find the exact 75th percentile speed.

            <p>For instance, if the speed at position 75 is 25 km/h and at position 76 is 26 km/h, you would take a weighted average based on the decimal part (0.75) to calculate the 75th percentile speed:</p>
            <pre>75th percentile speed = 25 + 0.75 × (26 - 25) = 25.75 km/h</pre>
        </li>
    </ol>

    <p>In summary, the 75th percentile speed is the value below which 75% of your speeds fall, and above which the top 25% of your speeds are recorded. It reflects a high-performing segment of your ride.</p>


                </div>
          
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Privacy;
