
export function setCookie(name, value, options = {}) {
    const { expires, path, domain, secure } = options;
    let cookieString = `${name}=${escape(value)};`;

    if (expires) {
        if (expires instanceof Date) {
            cookieString += `expires=${expires.toGMTString()};`;
        } else {
            throw new Error('cookie expires must be a Date object');
        }
    }

    if (path) {
        cookieString += `path=${escape(path)};`;
    }

    if (domain) {
        cookieString += `domain=${escape(domain)};`;
    }

    if (secure) {
        cookieString += 'secure;';
    }

    document.cookie = cookieString;
}

export function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const cookieName = cookie.split('=')[0];
        const cookieValue = cookie.split('=')[1];
        if (cookieName === name) {
            return cookieValue;
        }
    }
    return undefined;
}