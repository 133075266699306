/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Icons from "views/Icons.js";
import Map from "views/Map.js";
import Notifications from "views/Notifications.js";
import Rtl from "views/Rtl.js";
import Activities from "views/getactivities.js";
import Typography from "views/Typography.js";
import Privacy from "views/Privacy.js";
import Calculation from "views/Calculation.js";

import UserProfile from "views/UserProfile.js";
import Features from "views/features.js";
import Cookies from "views/cookies";
import FAQ from "views/faq.js";


var routes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: <Dashboard />,
  //   layout: "/admin",
  // },

  {
    path: "/features",
    name: "Features",
    rtlName: "خرائط",
    icon: "tim-icons icon-app",
    component: <Features />,
    layout: "/admin",
  },
  
  {
    path: "/getactivities",
    name: "View your activities",
    rtlName: "قائمة الجدول",
    icon: "tim-icons icon-cloud-download-93",
    component: <Activities />,
    layout: "/admin",
  },
  {
    path: "/calculation",
    name: "Calculation",
    rtlName: "خرائط",
    icon: "tim-icons icon-components",
    component: <Calculation />,
    layout: "/admin",
  },
  
  {
    path: "/user-profile",
    name: "About",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-single-02",
    component: <UserProfile />,
    layout: "/admin",
  },
  {
    path: "/Privacy",
    name: "Privacy",
    rtlName: "طباعة",
    icon: "tim-icons icon-align-center",
    component: <Privacy />,
    layout: "/admin",
  },
  
  {
    path: "/Cookies",
    name: "Cookies",
    rtlName: "طباعة",
    icon: "tim-icons icon-align-center",
    component: <Cookies />,
    layout: "/admin",
  },
  
  {
    path: "/faq",
    name: "FAQ",
    rtlName: "طباعة",
    icon: "tim-icons icon-align-center",
    component: <FAQ />,
    layout: "/admin",
  },
  
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: "tim-icons icon-align-center",
  //   component: <Typography />,
  //   layout: "/admin",
  // },  
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: "tim-icons icon-atom",
  //   component: <Icons />,
  //   layout: "/admin",
  // },

  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   rtlName: "ار تي ال",
  //   icon: "tim-icons icon-world",
  //   component: <Rtl />,
  //   layout: "/rtl",
  // },
];
export default routes;
