/*App.js*/

import React, { useState, useEffect, useMemo } from 'react';
import { Image } from 'react-bootstrap';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { setCookie, getCookie } from './utils'; // Import the functions

import { GoogleOAuthProvider } from '@react-oauth/google';

import connectlogo from "assets/img/btn_strava_connectwith_orange.png";




const login_api_url = process.env.REACT_APP_LOGIN_API_URL;
const resource_api_url = process.env.REACT_APP_RESOURCE_API_URL;
console.log('login_api_url', login_api_url); 
console.log('resource_api_url', resource_api_url);

function App() {
    const [showGoogleLogin, setShowGoogleLogin] = useState(true);
    const [user, setUser] = useState(null);
    const [strava_data, setStrava_data] = useState(null);
    const [listItems, setListItems] = useState(null);
    const [responseData, setResponseData] = useState({});
    const [loadingstatus, setLoadingstatus] = useState({});
    const [menu, setMenu] = useState(null);
    const [status, setStatus] = useState(null);
    const [showConnectStravaButton, setShowConnectStravaButton] = useState(false);
    const [units, setUnits] = useState('metric');

    function onClickHandler(){
        
        setMenu(<div className="content">After logging into google, please wait a few seconds for this page to load <br /><br/>{googleLoginButton}</div>);
      }

    const googleLoginButton = useMemo(() => {

        return (
            <GoogleLogin
                shape='pill'
                click_listener={() => onClickHandler()}
                onSuccess={credentialResponse => {
                    axios.post(`${login_api_url}/api/Login`, {
                        test: 'test'
                    }, {
                        headers: {
                            Authorization: credentialResponse.credential,
                        }
                    })
                        .then((res) => {
                            setCookie('stratava_token', res.data.token, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) });
                            setShowGoogleLogin(false);
                            checkToken();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
                
            />
        );
    }, []);

    const stravaConectButton = useMemo(() => {
      return (
          <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                  const clientId = '13077';
                  const redirectUri = `${resource_api_url}/api/Store_Tokens`;
                  const scope = 'read,activity:read_all';
                  const state = getCookie('stratava_token');
                  const authUrl = `https://www.strava.com/oauth/authorize?state=${state}&client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&approval_prompt=force&scope=${scope}`;
                  window.open(authUrl, '_self');
              }}
          >
              <img src={connectlogo} alt="Connect with Strava" />
              
          </div>
      );
  }, []);

    function checkToken() {
        const cookieValue = getCookie('stratava_token');
        if (cookieValue) {
            axios.get(`${login_api_url}/api/CheckToken`, {
                headers: {
                    Authorization: cookieValue,
                }
            })
                .then(response => {
                    if (response.data.userid) {
                        setUser(response.data.userid);
                        setStatus(response.status);
                    }
                })
                .catch(error => {
                    setStatus(error.response.status);
                });
        } else {
            setStatus(401);
            setShowGoogleLogin(true);
        }
    }

    useEffect(() => {
        switch (status) {
            case 401:
                setMenu(<div className="content">Welcome, this is a first-time visit. Please log in using Google <br />{googleLoginButton}</div>);
                break;
            case 202:
                setMenu(<div className="content">Logged in / Authenticated, checking if we have your Strava tokens.</div>);
                axios.get(`${resource_api_url}/api/User`, {
                    headers: {
                        Authorization: getCookie('stratava_token'),
                    }
                })
                    .then((res) => {
                        setStatus(res.status);
                    })
                    .catch((err) => {
                        setStatus(err.response.status);
                    });
                break;
            case 204:
                setMenu(<div className="content">We have your Strava tokens to log in!</div>);
                axios.get(`${resource_api_url}/api/Get_Activities`, {
                    headers: {
                        Authorization: getCookie('stratava_token'),
                    }
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setStrava_data(res.data);
                        } else {
                            setStatus(res.status);
                        }
                    })
                    .catch((err) => {
                        setStatus(err.response.status);
                    });
                break;
            case 404:
                setMenu(<div className="content">Please connect to Strava to view alternative stats for your rides. We value your privacy, please read our <a href='http://localhost:3000/admin/Privacy' target='frfr'>privacy policy</a> to learn what happens with your data when you connect. Please note, you will be prompted to assign read permissions to your ride info, including private rides.   <br />{stravaConectButton}</div>);
                break;
            case 440:
                setMenu(<div className="content">Session Expired, please log in again <br /><br/>{googleLoginButton}</div>);
                setShowGoogleLogin(true);
                break;
            default:
                console.log('Unknown status');
                checkToken();
                break;
        }
    }, [status, googleLoginButton]);

    const updateStatus = (entryId, status) => {
        return new Promise((resolve) => {
            setLoadingstatus(prevStatus => {
                const newStatus = { ...prevStatus, [entryId]: status };
                resolve(newStatus);
                return newStatus;
            });
        });
    };

    const handleButtonClick = (entry) => {
        // First update the status
        setLoadingstatus(prevStatus => ({
            ...prevStatus,
            [entry.id]: 'Getting data...'
        }));

        // Ensure the UI updates before making the API call
        setTimeout(() => {
            axios.post(`${resource_api_url}/api/Get_Activity`, {
                activity: entry
            }, {
                headers: {
                    Authorization: getCookie('stratava_token'),
                }
            })
                .then((res) => {
                    setLoadingstatus(prevStatus => ({
                        ...prevStatus,
                        [entry.id]: 'Here is your data'
                    }));
                    setResponseData(prevData => ({
                        ...prevData,
                        [entry.id]: res.data
                    }));
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        setShowGoogleLogin(true);
                    } else if (err.response.status === 404) {
                        setShowConnectStravaButton(true);
                    }
                });
        }, 100); // Adding a small delay to ensure UI update
    };

    const formatPace = (paceInMinutes) => {
        const minutes = Math.floor(paceInMinutes);
        const seconds = Math.round((paceInMinutes - minutes) * 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    
    useEffect(() => {
        if (strava_data) {
            
            const listItems = strava_data.map(entry => (
              entry.distance > 0 && (
                <div className="content" key={entry.id}>
                  <br />
                  <li>{entry.start_date.split('T')[0].split('-').reverse().join('-')} - {entry.start_date.split('T')[1].split(':')[0] > 12 ? entry.start_date.split('T')[1].split(':')[0] - 12 : entry.start_date.split('T')[1].split(':')[0]}:{entry.start_date.split('T')[1].split(':')[1]}
                    {entry.start_date.split('T')[1].split(':')[0] > 12 ? 'PM' : 'AM'} <br /> {entry.name}      
                    {' '}
                    <a className="Stravalink" href={`https://www.strava.com/activities/${entry.id}`} target="_blank" rel="noreferrer">(View on Strava)</a> <br />
                     {Math.round(entry.elapsed_time / 60)} Minutes
                  </li>
                  <button onClick={() => handleButtonClick(entry)}>Get Activity Stats</button>
                  <p>{loadingstatus[entry.id]}</p>
                  {responseData[entry.id] && (
                    <ul>
<li>
    <strong>Avg (considering all data):  </strong> 
    {units === 'metric' 
        ? `${(responseData[entry.id]['avg'] * 3.6).toFixed(2)} km/h -- pace: ${formatPace(60 / (responseData[entry.id]['avg'] * 3.6))} /km`
        : `${(responseData[entry.id]['avg'] / 1.60934).toFixed(2)} mp/h -- pace: ${formatPace(60 / (responseData[entry.id]['avg'] / 1.60934))} /mile`}
</li>
<li>
    <strong>50% Qrt Speed (50% of the time you went at least):  </strong> 
    {units === 'metric' 
        ? `${responseData[entry.id]['50% Qrt Speed']} km/h : pace: ${formatPace(60 / responseData[entry.id]['50% Qrt Speed'])} /km`
        : `${(responseData[entry.id]['50% Qrt Speed'] / 1.60934).toFixed(2)} mp/h -- pace: ${formatPace(60 / (responseData[entry.id]['50% Qrt Speed'] / 1.60934))} /mile`} 
</li>
<li>
    <strong>75% Qrt Speed (25% of the time you went at least): </strong> 
    {units === 'metric' 
        ? `${responseData[entry.id]['75% Qrt Speed']} km/h : pace: ${formatPace(60 / responseData[entry.id]['75% Qrt Speed'])} /km`
        : `${(responseData[entry.id]['75% Qrt Speed'] / 1.60934).toFixed(2)} mp/h -- pace: ${formatPace(60 / (responseData[entry.id]['75% Qrt Speed'] / 1.60934))} /mile`}
</li>
<br />
{responseData[entry.id]['Best 10 min Speed']['time'] > 0 && (
<li>
    <strong>Best 10 min Speed:  </strong> 
    {units === 'metric' 
        ? `${responseData[entry.id]['Best 10 min Speed'].speed} km/h : pace: ${formatPace(60 / responseData[entry.id]['Best 10 min Speed'].speed)} /km`
        : `${(responseData[entry.id]['Best 10 min Speed'].speed / 1.60934).toFixed(2)} mp/h : pace: ${formatPace(60 / (responseData[entry.id]['Best 10 min Speed'].speed / 1.60934))} /mile`} 
    <br />
    <strong>Time: </strong> {responseData[entry.id]['Best 10 min Speed'].time} minutes <br />
    <strong>Distance: </strong> {responseData[entry.id]['Best 10 min Speed'].distance} km
</li>
)}
{responseData[entry.id]['Best 20 min Speed']['time'] > 0 && (
<li>
    <strong>Best 20 min Speed:  </strong> 
    {units === 'metric' 
        ? `${responseData[entry.id]['Best 20 min Speed'].speed} km/h : pace: ${formatPace(60 / responseData[entry.id]['Best 20 min Speed'].speed)} /km`
        : `${(responseData[entry.id]['Best 20 min Speed'].speed / 1.60934).toFixed(2)} mp/h : pace: ${formatPace(60 / (responseData[entry.id]['Best 20 min Speed'].speed / 1.60934))} /mile`} 
    <br />
    <strong>Time: </strong> {responseData[entry.id]['Best 20 min Speed'].time} minutes <br />
    <strong>Distance: </strong> {responseData[entry.id]['Best 20 min Speed'].distance} km
</li>
)}
{responseData[entry.id]['Best 30 min Speed']['time'] > 0 && (
    <li>
        <strong>Best 30 min Speed: </strong> 
        {units === 'metric' 
            ? `${responseData[entry.id]['Best 30 min Speed'].speed} km/h : pace: ${formatPace(60 / responseData[entry.id]['Best 30 min Speed'].speed)} /km`
            : `${(responseData[entry.id]['Best 30 min Speed'].speed / 1.60934).toFixed(2)} mp/h : pace: ${formatPace(60 / (responseData[entry.id]['Best 30 min Speed'].speed / 1.60934))} /mile`}
        <br />
        <strong>Time: </strong> {responseData[entry.id]['Best 30 min Speed'].time} minutes <br />
        <strong>Distance: </strong> {units === 'metric' ? 
        `${responseData[entry.id]['Best 30 min Speed'].distance} km` : `${(responseData[entry.id]['Best 30 min Speed'].distance / 1.60934).toFixed(2)} miles`}
    </li>
)}

<br />
<li>
    <strong>Heart Rate Drop / Recovery:  </strong> 
    {responseData[entry.id]['HRD']['largest_drop'].toFixed(2)} bpm @ {(responseData[entry.id]['HRD']['time_bucket']/60).toFixed(2)} minutes
    
    <br />
  

</li>

</ul>

                  )}



                      {/* {Object.entries(responseData[entry.id]).map(([key, value], index) => (
                        <li key={index}><strong>{key}:</strong> {value}</li>
                      ))} */}
                    
                </div>
              )
            ));
            setListItems(listItems);
        }
    }, [strava_data, responseData, loadingstatus, units]);

    return (
      
        <div className="content">
               
            {!showGoogleLogin && <p>{user}</p>}
            <br />
            {menu}
            <div className="settings">
                    <h4>Settings:</h4>
                    <lu>
                        <li>
                        <label>
                        <input
                            type="radio"
                            value="metric"
                            checked={units === 'metric'}
                            onChange={() => setUnits('metric')}
                        />
                        Metric
                    </label>
                        </li>
                        <li>
                        <label>
                        <input
                            type="radio"
                            value="imperial"
                            checked={units === 'imperial'}
                            onChange={() => setUnits('imperial')}
                        />
                        Imperial
                    </label>
                    </li>
                    </lu>
                    
                    
                </div>                
            {listItems && (
            <ul>
            {listItems}
        </ul>
            
            )}
            
            
        </div>
    );
}

export default App;
