/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

function Privacy() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="10">
            <Card>
              
              <CardBody>
                <div className="typography-line">
                <h1>
Cookie Policy for Iron Drive Stats
</h1>
<p>
At Iron Drive Stats, we do not use marketing or analytics cookies. However, we do use a functional cookie to enhance your experience. This cookie stores your encrypted session sign-in/login token  for a duration of 3 hours, ensuring that you do not have to sign in with Google every time you visit the page within this period.
</p>
<br/>

<p>
By using the website, and specifically when you use the functionality "View Your Activities" by signing in with Google, you agree to the use of this functional cookie. This cookie is essential for the functionality of the website and is automatically becomes invalid after 3 hours.
</p>
<br/>
<p>
For more information on how we handle your data, please review our Privacy Policy.
</p>
<br/>


                </div>
          
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Privacy;
