/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";


import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));

// Parse URL parameters to check for `redirect=true`
const searchParams = new URLSearchParams(window.location.search);
const shouldRedirect = searchParams.get("redirect") === "true";
const navigatePrivacy = searchParams.get("page") === "privacy";
const navigateFAQ = searchParams.get("page") === "faq";

root.render(
<GoogleOAuthProvider clientId="872824279529-j20gg3tvki4aiaqtkka7v31h8v0u9fe0.apps.googleusercontent.com"  >
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <BrowserRouter>
        <Routes>
          {/* Conditionally redirect to /admin/getactivities based on URL parameter */}
          {shouldRedirect ? (
              <>
              <Route path="/admin/*" element={<AdminLayout />} />
              <Route path="/rtl/*" element={<RTLLayout />} />
              <Route path="*" element={<Navigate to="/admin/getactivities" replace />} />
            </>  
            ) : navigatePrivacy ? (
              <>
              <Route path="/admin/*" element={<AdminLayout />} />
              <Route path="/rtl/*" element={<RTLLayout />} />
              <Route path="*" element={<Navigate to="/admin/Privacy" replace />} />
            </>              
            ) : navigateFAQ ? (
              <>
              <Route path="/admin/*" element={<AdminLayout />} />
              <Route path="/rtl/*" element={<RTLLayout />} />
              <Route path="*" element={<Navigate to="/admin/FAQ" replace />} />
            </>  
            ) : (
              <>
                {/* <Route path="/admin/Privacy" element={<AdminLayout />} /> */}
                <Route path="/admin/Privacy" element={<Navigate to="/admin/Privacy" replace />} />
                
                <Route path="/admin/*" element={<AdminLayout />} />
                <Route path="/rtl/*" element={<RTLLayout />} />
                <Route path="*" element={<Navigate to="/admin/features" replace />} />
              </>
            )}
          
        </Routes>
      </BrowserRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
  </GoogleOAuthProvider>
);
