/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

function Privacy() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="10">
            <Card>
              
              <CardBody>
                <div className="typography-line">
              
<h1>
Privacy Policy for Iron Drive Stats
</h1>
<p>
At Iron Drive Stats, we value and respect your privacy. This policy outlines the types of information we collect, how we use it, and the steps we take to protect your data, in accordance with relevant data protection laws such as the GDPR.
</p>
<br/>

<h2>
1. Information We Collect
</h2>
<p>
When you log into Iron Drive Stats using Google, we only collect your name as provided by the Google login functionality. This name is solely used to identify your Strava tokens, which allow us to retrieve your latest cycling and running activities. We strictly adhere to Strava’s API guidelines and only request read permissions from your Strava account.
</p>
<br/>

<h2>
2. Use of Strava Data
</h2>
<p>
Iron Drive Stats will not modify or store your cycling and running activity data. Instead, we use tokens to securely pull your latest rides and runs as needed. The data is displayed to you during your session and is not stored in our system. We ensure that our use of your data complies with Strava’s API agreement, which prohibits sharing, selling, or using the data for purposes such as training AI models.
</p>
<br/>

<h2>
3. Data Storage and Security
</h2>
<p>
The tokens we collect to access your Strava data are securely stored in our Azure backend infrastructure. We implement industry-standard security measures, including encryption and secure transmission (e.g., HTTPS), to protect your data. We are committed to respecting your privacy settings, as defined in your Strava account, and complying with all applicable data protection laws.
</p>
<br/>

<h2>
4. User Control
</h2>
<p>
You have full control over the data you share with Iron Drive Stats. At any time, you can revoke Iron Drive Stats' access to your Strava account by adjusting your permissions in Strava. Additionally, if you choose to stop using our services, all tokens and access data will be deleted from our system upon request.
</p>
<br/>

<h2>
5. Changes to this Privacy Policy
</h2>
<p>
We reserve the right to modify this privacy policy at any time, in accordance with changes to our services or Strava’s API guidelines. Any updates will be posted here, and we encourage you to review this page regularly to stay informed about how we protect your data.
</p>
<br/>

                </div>
          
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Privacy;
