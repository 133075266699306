/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

function features() {
  return (
    <>
      <div className="content">
        <Row>
          <Col md="10">
            <Card>
              
              <CardBody>
                <div className="typography-line">
              
    <h1>Activity Overview and Advanced Speed Analysis</h1>
    <p>Our app seamlessly connects with your Strava account, allowing you to view your latest cycling, running, or any type of activity data. For each activity that includes distance, we provide a detailed breakdown to help you analyze and improve your performance. A demo is given below (pls note that the video is a bit outdated, the app has more features by now (pls see below). Sign in proces is the same though):</p><br/>
    <iframe width="100%" height="500" src="https://www.youtube.com/embed/jdpLdmoKcCo" title="Irondrivestats.com" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    <h2>Key Features:</h2>
    <ul>
    <li><strong>Latest Activity Feed:</strong> View a list of your most recent rides, runs, or other activities, each displayed with the total distance covered.</li>
    <li><strong>Detailed Speed Metrics:</strong> Each activity has a dedicated button that, when pressed, reveals detailed speed metrics, including:
        <ul>
            <li><strong>50th Percentile Speed (Median Speed):</strong> Understand your median speed—where half of your speeds were faster and half were slower. This is an excellent measure of your typical pace, eliminating the impact of extremes like stops or very slow sections.</li>
            <li><strong>75th Percentile Speed (Upper Quartile Speed):</strong> View the speed at which you were faster than 75% of your activity. This highlights your stronger performance segments, allowing you to track when and where you achieved higher speeds during the ride or run.</li>
        </ul>
    </li>
    <li><strong>Best Speeds Held:</strong> Track your top speeds over different timeframes with dedicated metrics for 10, 20, and 30 minutes. This feature highlights your peak performance intervals, allowing you to see how long you can maintain high speeds during your activities.</li>
    <li><strong>Heart Rate Recovery (HRR):</strong> Gain valuable insights into your cardiovascular fitness with our heart rate recovery feature. The app calculates your average heart rate for each minute during your ride or run, identifying the minute with the most significant drop in beats per minute post-exercise. This drop is reported, providing a clear measure of how quickly your heart rate returns to baseline after intense activity.</li>
</ul>

<h2>Benefits of These Metrics:</h2>
<ul>
    <li><strong>Better Insight Into High-Performance Segments:</strong> Moving speed and 75th percentile speed give you a focused view of your fastest sections, helping you track improvement and identify where you're performing at your best.</li>
    <li><strong>Improved Training and Benchmarking:</strong> By understanding both the median and 75th percentile speeds, you can better gauge your current fitness level and set realistic performance benchmarks. These metrics help you focus on not just overall speed, but how consistently you're maintaining higher speeds.</li>
    <li><strong>Clearer Pacing Strategy:</strong> With a breakdown of your speeds across percentiles, you gain insight into how well you're pacing yourself during a ride or run. This can guide your training to optimize endurance and speed over longer distances.</li>
    <li><strong>Actionable Insights for Race Prep:</strong> For competitive athletes, the ability to analyze high-speed sections (above 20 km/h) and track median and quartile speeds can help fine-tune performance strategies for upcoming races.</li>
    <li><strong>Enhanced Performance Tracking:</strong> The best speeds held for 10, 20, and 30 minutes give you a focused view of your endurance and speed capabilities, helping you identify your strongest segments and monitor improvements over time.</li>
    <li><strong>Comprehensive Fitness Assessment:</strong> The heart rate recovery metric offers a deeper understanding of your cardiovascular health, highlighting your recovery efficiency. A significant drop in heart rate indicates good fitness levels and effective training, providing motivation to improve further.</li>
    <li><strong>Tailored Training Insights:</strong> With detailed metrics on speed and heart rate recovery, you can optimize your training regimens. Focus on maintaining peak speeds and improving your recovery times to enhance overall performance and stamina.</li>
    <li><strong>Data-Driven Race Preparation:</strong> For competitive athletes, these metrics are essential for race day strategy. By analyzing your best speeds and recovery rates, you can better prepare for the demands of your next event, ensuring you're in peak condition to compete.</li>
</ul>

    <p>This combination of data empowers you to not only track your performance but also gain deeper insights into your strengths and areas for improvement, giving you the tools to take your training to the next level.</p>

                </div>
          
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default features;
